<template>
  <div class="report-table">
    <table>
      <thead>
        <tr class="head-cell">
          <th v-for="(head, index) in headers" :key="index">{{ head }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in rows" :key="index">
          <td v-for="(header, i) in headers" :key="i" v-bind:class="{ 'neutral-background': index % 2 > 0 }">
            <a v-if="header === 'Comentário'">
              <div v-if="row[header] !== '-'">
                <span>
                  <p v-html="row[header]" />
                </span>
              </div>
            </a>
            <a class="cellphone" v-else-if="header === 'Número de celular'">
              <div class="whatsapp-link" @click="openWhatsApp(row[header])" v-if="row[header] !== '-'">
                <span>
                  <p>{{ row[header] }}</p>
                </span>
                <WhatsAppIcon />
              </div>
            </a>

            <p v-else-if="!isMultiple(row[header])" v-bind:class="{ 'align-center': row[header] === '-' }">
              {{ row[header] }}
            </p>

            <div v-else :id="`${header}-${index}`">
              <span v-if="header === 'Solic. de exames'"> {{ row[header][0] }}
                <b-badge 
                  v-if="getFilteredRows(row[header]).length > 1"
                  variant="primary"
                  class="badge"  
                >
                  +{{ row[header].length - 1 }}
                </b-badge></span>
              <span v-else-if="header === 'Solic. de cirurgia'">{{ row[header][0]['cirurgia'] }}</span>
              <span v-else-if="header === 'CID'">{{ row[header][0]['cid'] }}</span>
              <div v-else >
                {{ row[header][0] }}
                <b-badge 
                  v-if="getFilteredRows(row[header]).length > 1" 
                  variant="primary"
                  class="badge"  
                >
                  +{{ row[header].length - 1 }}
                </b-badge>
              </div>
              <b-popover 
                v-if="getFilteredRows(row[header]).length > 1" 
                :target="`${header}-${index}`" 
                triggers="hover" 
                class="popover"
                placement="top"
              >
                <p v-for="el, j in getFilteredRows(row[header])" :key="j">
                  {{ el }}
                </p>
              </b-popover>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'reports-table',

  components: { 
    WhatsAppIcon: () => import('@/assets/icons/whatsapp-icon.svg') 
  },

  props: {
    headers: Array,
    rows: Array,
  },

  data() {
    return {
      multiTypes: ['array', 'object'],
    }
  },

  methods: {
    isMultiple(data) {
      return Array.isArray(data)
    },
    openWhatsApp(value) {
      const whatsappNumber = value.replace(/\D/g, '')
      window.open(`https://api.whatsapp.com/send?phone=55${whatsappNumber}`, '_blank')
    },
    getFilteredRows (rows) {
      return [...new Set (rows?.filter(el => el))]
    }
  },
}
</script>

<style lang="scss" scoped>

.popover {
  top: 80px !important;
}
.report-table {
  max-height: 60vh;
  width: 100% !important;
  margin: 50px auto;
  overflow: scroll;

  table {
    width: 100%;
    overflow: scroll;
    padding: 2px;
    /* table-layout: fixed; */
    position: relative;
    border-collapse: collapse;

    tr.head-cell th {
      background-color: #F8F8FD !important;
    }

    .neutral-background {
      background-color: #F8F8FD !important;
    }

    .align-center {
      margin-left: -50px;
      text-align: center;
    }

    th {
      padding: 50px !important;
      position: sticky;
      top: 0;
      z-index: 5;
    }

    td {
      font-family: Nunito Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      .badge {
        margin-left: 5px;
      }
    }

    th,
    td {
      overflow-x: hidden;
      min-width: 180px !important;
      text-align: start;
      padding: 25px 0 25px 10px !important;
      border-bottom: 1px dotted var(--neutral-300);
    }

  }

}

.cellphone {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin-bottom: auto;

  p {
    color: var(--type-active);
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }

  .whatsapp-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    span {
      font-weight: 700;

      p {
        color: var(--blue-500);
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        text-decoration: underline;
      }
    }

    input {
      border: 1px solid #ffffff !important;
      width: 120px;
      display: inline-block;
      padding: 5px 16px;

      &:focus {
        border: 1px solid var(--blue-600) !important;
      }
    }

    svg {
      height: 28px;
      width: 28px;
    }
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>